import React, {useEffect, useState, useRef} from 'react';
import AdminHeader from '../Section/AdminHeader';
import { ReactTabulator } from 'react-tabulator';
import * as H from '../../Styles/StyleComponentHan';
import {
  get_portfolio,
  update_portfolio_order,
  delete_portfolio,
} from "../../Axios";

export function AdminPortfolioManagement() {  
  const [portfolioData, setPortfolioData] = useState([]); 
  const tabulatorRef = useRef();

  const row_moved_function = (e, row) => {  
    let temp_portfolio_order_array = [];
    let portfolio_order= 0;

    tabulatorRef.current.optionsList.table.rowManager.activeRows.forEach(element =>{
      temp_portfolio_order_array.push({
        portfolio_order: portfolio_order,
        portfolio_ID: element.data.portfolio_ID,
      })
      portfolio_order++;
    });
    update_portfolio_order(temp_portfolio_order_array).then(result=>{
      if(result === undefined){
        alert('순서가 변경되지 않았습니다. 관리자한테 확인해주세요.');
      }
    })    
  };

  const check_portfolio_edit = () =>{
    if(tabulatorRef.current.getSelectedData().length > 1){
      alert('수정할 데이터를 하나만 선택해주세요.');
    } else if(tabulatorRef.current.getSelectedData().length === 0){
      alert('수정할 데이터를 선택해주세요.');
    } else{
      window.location.href = `/AdminPortfolioManagementAdd?portfolio_ID=${tabulatorRef.current.getSelectedData()[0].portfolio_ID}`;
    }
  }

  const check_portfolio_delete = () =>{
    if(tabulatorRef.current.getSelectedData().length === 0){
      alert('수정할 데이터를 선택해주세요.');
    } else{
      let temp_delete_array = [];
      tabulatorRef.current.getSelectedData().forEach((element)=>{
        temp_delete_array.push(element.portfolio_ID);
      })
      delete_portfolio(temp_delete_array).then((result)=>{
        if(result === undefined){
          alert('삭제가 되지 않았습니다. 관리자한테 확인해주세요.');
        }else{
          alert('삭제가 완료 되었습니다.');
          window.location.reload();
        }
      })
    }
  }

  useEffect(() => {
    get_portfolio().then(result_data=>{
      setPortfolioData(result_data);
    })
  },[]);

  return (
    <H.admin_MainWrap>
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>메뉴 관리 - 포토폴리오</p>
            <div>
              <H.admin_addBtn
                onClick={()=>{window.location.href = "/AdminPortfolioManagementAdd";}}>등록
              </H.admin_addBtn>
              <H.admin_changeBtn
                onClick={()=>{check_portfolio_edit();}}>수정
              </H.admin_changeBtn>
              <H.admin_deleteBtn
                onClick={()=>{check_portfolio_delete();}}>삭제
              </H.admin_deleteBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>

        <H.admin_rightInfoBox>{/* 우측 아래 */}
          <H.admin_rightInfoInner>
            <H.listInfoText>
              <p className="listInfoTextBold">사용 방법 안내</p>
              <p>1. 리스트 중 상단이 정렬 우선순위가 높습니다.</p>
              <p>2. 왼쪽 3선 작대기를 클릭 ➡️ 드래그하여 위/아래로 이동할 수 있습니다.</p>
            </H.listInfoText>
            {
            portfolioData.length !== 0 ? 
            <ReactTabulator
              onRef={(r) => (tabulatorRef.current = r.current)}
              data={portfolioData}
              columns={[
                {rowHandle:true, formatter:"handle", headerSort:false, frozen:true, width:30, minWidth:30},
                {formatter:"rowSelection", titleFormatter:"rowSelection", headerSort:false, 
                  cellClick:function(e, cell){
                    cell.getRow().toggleSelect();
                  }
                },
                { title: "ID", field: "portfolio_ID", headerSort:false},
                { title: "메인 타이틀", field: "portfolio_title", headerSort:false},
                { title: "서브 타이틀", field: "portfolio_sub_title", headerSort:false},
                { title: "사용 여부", field: "portfolio_status", headerSort:false},
                { title: "등록 일자", field: "portfolio_registe", headerSort:false},
                { title: "수정 일자", field: "portfolio_modified", headerSort:false},
              ]}
              layout={"fitData"}
              options={{
                movableRows: true,
              }}
              events={{
                rowMoved:row_moved_function
              }}
              />
            : 
            <div>
              <p>데이터가 없습니다.</p>  
            </div>
            }
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

export default AdminPortfolioManagement;
