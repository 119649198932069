import React, {useEffect, useState, useRef} from 'react';
import AdminHeader from '../Section/AdminHeader';
import { ReactTabulator } from 'react-tabulator';

import * as H from '../../Styles/StyleComponentHan';

import {
  get_rolling_banner,
  update_rolling_banner_order,
  delete_rolling_banner,
} from "../../Axios";

export function AdminRollingBannerManagement() {  

  const [rollingBannerData, setRollingBannerData] = useState([]); 

  const tabulatorRef = useRef();

  const row_moved_function = (e, row) => {  
    let temp_rolling_banner_order_array = [];
    let rolling_banner_order= 0;
    tabulatorRef.current.optionsList.table.rowManager.activeRows.forEach(element =>{
      temp_rolling_banner_order_array.push({
        rolling_banner_order: rolling_banner_order,
        rolling_banner_ID: element.data.rolling_banner_ID,
      })
      rolling_banner_order++;
    });
    update_rolling_banner_order(temp_rolling_banner_order_array).then(result=>{
      if(result === undefined){
        alert('순서가 변경되지 않았습니다. 관리자한테 확인해주세요.');
      }
    })    
  };

  const check_rolling_banner_edit = () =>{
    if(tabulatorRef.current.getSelectedData().length > 1){
      alert('수정할 데이터를 하나만 선택해주세요.');
    } else if(tabulatorRef.current.getSelectedData().length === 0){
      alert('수정할 데이터를 선택해주세요.');
    } else{
      window.location.href = `/AdminRollingBannerManagementAdd?rolling_banner_ID=${tabulatorRef.current.getSelectedData()[0].rolling_banner_ID}`;
    }
  }

  const check_rolling_banner_delete = () =>{
    if(tabulatorRef.current.getSelectedData().length === 0){
      alert('삭제할 데이터를 선택해주세요.');
    } else{
      let temp_delete_array = [];
      tabulatorRef.current.getSelectedData().forEach((element)=>{
        temp_delete_array.push(element.rolling_banner_ID);
      })
      delete_rolling_banner(temp_delete_array).then((result)=>{
        if(result === undefined){
          alert('삭제가 되지 않았습니다. 관리자한테 확인해주세요.');
        }else{
          alert('삭제가 완료 되었습니다.');
          window.location.reload();
        }
      })
    }
  }

  useEffect(() => {
    get_rolling_banner().then(result_data=>{
      setRollingBannerData(result_data);
    })
  },[]);

  return (
    <H.admin_MainWrap> {/* 전체박스 */}
      <AdminHeader/> {/* 헤더 */}
      <H.admin_rightWrap> {/* 우측 전체박스*/}
        <H.admin_rightTitBox>{/* 우측 박스 */}
          <H.admin_rightTitInner>{/* 우측 이너이너 */}
            <p>메인화면 관리 - 롤링 배너</p>
            <div>
              <H.admin_addBtn
                onClick={()=>{window.location.href = "/AdminRollingBannerManagementAdd";}}>등록
              </H.admin_addBtn>
              <H.admin_changeBtn
                onClick={()=>{check_rolling_banner_edit();}}>수정
              </H.admin_changeBtn>
              <H.admin_deleteBtn
                onClick={()=>{check_rolling_banner_delete();}}>삭제
              </H.admin_deleteBtn>
            </div>
            </H.admin_rightTitInner>
          </H.admin_rightTitBox>
          
        <H.admin_rightInfoBox>{/* 우측 아래 */}
          <H.admin_rightInfoInner>
            <H.listInfoText>
              <p className="listInfoTextBold">사용 방법 안내</p>
              <p>1. 리스트 중 상단이 정렬 우선순위가 높습니다.</p>
              <p>2. 왼쪽 3선 작대기를 클릭 ➡️ 드래그하여 위/아래로 이동할 수 있습니다.</p>
            </H.listInfoText>
            {
            rollingBannerData.length !== 0 ? 
            <ReactTabulator
              onRef={(r) => (tabulatorRef.current = r.current)}
              data={rollingBannerData}
              columns={[
                {rowHandle:true, formatter:"handle", headerSort:false, frozen:true, width:30, minWidth:30},
                {formatter:"rowSelection", titleFormatter:"rowSelection", headerSort:false, 
                  cellClick:function(e, cell){
                    cell.getRow().toggleSelect();
                  }
                },
                { title: "ID", field: "rolling_banner_ID", headerSort:false},
                { title: "롤링 배너명", field: "rolling_banner_name", headerSort:false},
                { title: "사용 여부", field: "rolling_banner_status", headerSort:false},
                { title: "등록 일자", field: "rolling_banner_registe", headerSort:false},
                { title: "수정 일자", field: "rolling_banner_modified", headerSort:false},
              ]}
              layout={"fitData"}
              options={{
                movableRows: true,
              }}
              events={{
                rowMoved:row_moved_function
              }}
              />
            : 
            <div>
              <p>데이터가 없습니다.</p>  
            </div>
            }
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>

      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

export default AdminRollingBannerManagement;
