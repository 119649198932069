//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React from 'react';
import * as H from '../../Styles/StyleComponentHan';

export function Footer() {  

  return (
    <H.footerWrap>
      <div className="footerInner">
        <p>콘텐츠아카이브보다(Contents Archive Boda) / 대표이사 고태욱 </p>
        <p>Address 부산광역시 해운대구 반송로 877번길 60 해운대창조기업지원센터 302호</p>
        <p>Contact us 051-711-5257 </p>
        <p>Copyright © 콘텐츠아카이브보다 All Rights Reserved.</p>
      </div>
    </H.footerWrap>

  );
}

export default Footer;