//여기는 npm으로 설치한 패키지나 기본적으로 제공하는 패키지나 스스로 만든 패키지를 사용하기 위해 불러오는 공간입니다.
//예를들어 import React, {useState} from 'react'; 라고함은
//react에서 제공하는 패키지 중에 React와 useState 패키지를 가져와서 사용한다고 생각하시면 되고
//import {login_judgement} from "../../Axios"; 이거는 사용자가 만든
//Axios.js 파일안에 있는 login_judgement을 사용한다는 뜻이고요.
//가져올때는 import를 써야하며 내보내야 할때는 export를 앞에 써줘야 합니다.
//Axios.js파일에 가보면 export const login_judgement = async (hc_instructor_login_ID, hc_insturctor_password) => {
//라고 함수 선언 전에 export를 선언하였는데 저 함수는 import해서 사용할 수 있는 함수다 라고 생각하시면 됩니다.
//여기서 중요한 건 const인데 const는 한번 선언하면 값이 절대 바뀌지 않게 바꾸는 겁니다.
//이걸 타입이라고 하는데 자바스크립트는 const, var, let이 있으며
//바꾸고 싶지 않는 타입은 const, 바꾸고 싶은 타입은 let을 쓰고 var는 절대 쓰면 안됩니다. 
import React, {useState} from 'react';
import {login_judgement} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';
import admin_loginImg from '../../Images/cab_admin_loginImg.png';
import admin_logowhite from '../../Images/cab_logo_w.png';


function AdminLogin() {
  // 로그인 아이디와 비밀번호를 담을 수 있는 useState hook
  // useState는 React에서 제공하는 hook인데 
  // hook이라는 것은 과거 바닐라스크립트(기본 자바스크립트)의 불편을 최소화 시키고자 만든 것들이 hook이라고 함
  // hook은 여러가지가 있으며 useState는 그 많은 hook 중에 하나이며 제일많이 쓰는 hook임
  // useState는 실시간으로 사용자가 값을 입력하게 되면 값이 다른 작업이 필요없이 값이 최신화가 됨
  // 에를들어 아래 userLoginID와 setUserLoginID를 설명하면
  // userLoginID는 실제 값이고 setUserLoginID는 userLoginID에 값을 담을 수 있는 Function임
  // 값을 입력하면 어떻게 변화되는지는에 대한 내용은 아래 user_login_ID input에 설명해났음  
  const [userLoginID, setUserLoginID] = useState('');
  const [userPassword, setUserPassword] = useState('');

  //로그인 버튼을 클릭 했을 시 저장된 아이디인지 아닌지를 판단하는 Function
  const check_login = () => {
    //login_judgement Function은 Axios.js 파일 안에 있는 Function이며 자세한 내용은 해당폴더에 적어났음.
    //login_judgement Function에 매개변수 userLoginID와, userPassword를 보내서 return을 받아오는데 
    //그 리턴값을 result라고 명명하고 그 result라는 값에 따라 로그인을 정상적으로 했는지 안했는지 판단함
    login_judgement(userLoginID, userPassword).then((result)=>{
        //result === 0 이라는 것은 아이디 및 비밀번호를 체킹하여 없는 값일 경우 백엔드에서 0을 반환하는데 그럴 경우 알람창을 뛰웁니다.
        if(result === 0){
            alert('아이디 및 비밀번호를 확인해주세요.');
        //만약에 result가 0이 아닐경우에는 window.location.href를 통해 라우터 이동을 함
        //라우터 정보는 index.js 파일에 적어났음.
        }else{
            window.location.href = "/AdminMain";
        }
    });
  }

  //return이라고 함은 HTML을 뿌려주기 위한 장소라고 생각하시면 됩니다.
  //return이전에는 자바스크립트를 처리하는 공간이고 그 자바스크립트를 처리한 내용을 바탕으로 HTML이 뿌려지는 구조가 React 구조입니다.
  //사실 return이라고 함은 함수가 처리되고 나서 마지막으로 값을 반환시킬 것이 있다면 return이라고 쓰는데
  //여기서는 function Login() { 의 return값이 아래 HTML이라고 생각하시면 됩니다.
  //Axios.js가 보면 어떠어떠한 처리를 하고 꼭 나중에는 DB에서 받아온 값을 return을 써서 보내주거든요.
  //위의 login_judgement도 보면 userLoingID와 userPassword를 매개변수로하여 처리한 후에 result의 값으로 로그인을 했는지
  //안했는지 판단하는데 result라는 것이 사실 함수에서 return으로 나온 값을 result로 사용한다는 겁니다.
  return (

    <H.admin_LoginWrap admin_loginImg = {admin_loginImg}>
      <img src = {admin_loginImg} alt="배경이미지"></img>
      <H.admin_LoginInner>
        <H.admin_LoginLogo admin_logowhite = {admin_logowhite}></H.admin_LoginLogo>
        <H.admin_LoginFormBox>
          <H.admin_LoginTit>관리자 로그인</H.admin_LoginTit>
          <H.admin_LoginInputBox>
            {/* htmlFor는 id와 연동되는 것인데 
            예를들어 아래 로그인 input을 보면 id를 user_login_ID라 해났으며
            label을 클릭하면 user_login_ID를 클릭하는 것과 동일한 현상이 일어남*/}
            <label htmlFor='user_login_ID'>아이디</label>
            <input 
              type='text' 
              id = 'user_login_ID' 
              maxLength={50} 
              //onChange란 event라는 것인데 event도 여러개가 있음
              //onClick, onChange, onBlur 등등 여러가지 있음
              //아래는 onChange인데 onChange는 값이 변동이 될때마다 이벤트를 일으킵니다.
              //또 onChange안의 매개변수중에 event_data라고 있는데 어떤 event든
              //event가 일으킬때마다 데이터를 넘겨 줍니다. 그거를 event_data라고 정의한거고요.
              //그리고 setUserLoginID는 위 React hook에서 설명드렸을 시 userLoginID 값을 변동시키기 위해
              //쓰는 Function이고 자세한 내용은 위에서 살펴보세요.
              //결론적으로 값이 변동될때마다 userLoginID값을 계속계속 최신화 시키는 겁니다.
              //아 참고로 /> 앞에 엔터쳐서 on이라고 치면
              //자동완성 리스트가 뜨는데 event종류에 대해서 다뜸
              //엄청 많음 -_-;; 다 안씀 쓰고싶은것만 쓰면됨
              onChange={(event_data)=>{
                setUserLoginID(event_data.target.value);
              }} 
              placeholder = "아이디를 입력해 주세요."
            />
          </H.admin_LoginInputBox>
          <H.admin_LoginInputBox>
            <label htmlFor='user_password'>비밀번호</label>
            <input 
              type='password' 
              id = 'user_password' 
              maxLength={50}
              //onChange와 setUserPassword에 대한 자세한 설명은 해당 위의 로그인 아이디를 참조
              onChange={(event_data)=>{
                setUserPassword(event_data.target.value);
              }} 
              placeholder = "비밀번호를 입력해 주세요."
              />
          </H.admin_LoginInputBox>
  
            {/* onClick이란 event라는 것인데 event에 대한 내용은 위에 참조하고
            onClick이벤트는 클릭했을 시 이벤트임 */}
          <H.admin_LoginBtn onClick={()=>{
              //버튼을 클릭했을 시 위에 있는 check_login 함수를 호출
              check_login();
              }}>
              로그인
          </H.admin_LoginBtn>
          <H.admin_LoginSearch>
            {/* 아이디 및 비밀번호 찾기는 나중에 할 예정 */}
            <button>아이디 찾기</button>
            <span></span>
            <button>비밀번호 찾기</button>
          </H.admin_LoginSearch>
        </H.admin_LoginFormBox>
      </H.admin_LoginInner>
    </H.admin_LoginWrap>


  );
}

export default AdminLogin;