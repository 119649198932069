import React, {useState, useEffect} from 'react';
import AdminHeader from '../Section/AdminHeader';
import {
  insert_menu_permission,
  get_menu_permission_one,
  update_menu_permission,
} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';

export function MenuPermissionManagementAdd() {  
  const params = new URLSearchParams(window.location.search);
  const menu_permission_ID_param = params.get("menu_permission_ID");
  const [menuPermissionName, setMenuPermissionName] = useState('');
  const [menuPermissionArray, setMenuPermissionArray] = useState([]);
  const [menuPermissionStatus, setMenuPermissionStatus] = useState('1');
  const [menuPermissionNameError, setMenuPermissionNameError] = useState('');
  const [render, renderStatus] = useState(false);

  const menu_permission_check = () =>{
    let continue_add = true;
    if(menuPermissionName === ''){setMenuPermissionNameError('메뉴권한명을 등록해 주세요.'); continue_add = false;}else{setMenuPermissionNameError('');}
    if(continue_add){
      if(menu_permission_ID_param === null){
        insert_menu_permission(
          menuPermissionName,
          menuPermissionStatus,
          JSON.stringify(menuPermissionArray)
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/MenuPermissionManagement";
        });
      }else{
        update_menu_permission(
          menu_permission_ID_param,
          menuPermissionName,
          menuPermissionStatus,
          JSON.stringify(menuPermissionArray)
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/MenuPermissionManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  const insert_menu_permission_check_box = (event, big_menu_ID, menu_ID) =>{
    if(event.target.checked === true){
      if(!check_box_bool_judgement(big_menu_ID)){
        menuPermissionArray.push(big_menu_ID);
      }
      menuPermissionArray.push(menu_ID);
    }else{
      for(let i = 0; i < menuPermissionArray.length; i++) {
        if(menuPermissionArray[i] === menu_ID)  {
          menuPermissionArray.splice(i, 1);
          i--;
        }
      }
    }
    setMenuPermissionArray(menuPermissionArray);
    renderStatus(!render);
  }

  const insert_menu_permission_check_box_all = (event, menu_ID) =>{
    menu_ID.forEach(element => {
      if(event.target.checked === true){
        if(!check_box_bool_judgement(element)){
          menuPermissionArray.push(element);
        }
      }else{
        for(let i = 0; i < menuPermissionArray.length; i++) {
          if(menuPermissionArray[i] === element)  {
            menuPermissionArray.splice(i, 1);
            i--;
          }
        }
      }
    });
    setMenuPermissionArray(menuPermissionArray);
    renderStatus(!render);
  }

  const check_box_bool_judgement  = (menu_ID) =>{
    let bool = false;
    for(let i = 0; i < menuPermissionArray.length; i++) {
      if(menuPermissionArray[i] === menu_ID)  {
        bool = true;
      }
    }
    return bool;
  }

  useEffect(() => {
    if(menu_permission_ID_param !== null){
      get_menu_permission_one(menu_permission_ID_param).then((result) => {
        setMenuPermissionArray(JSON.parse(result[0].menu_permission_array));
        setMenuPermissionName(result[0].menu_permission_name);
        setMenuPermissionStatus(result[0].menu_permission_status);
      })
    }
  },[menu_permission_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>시스템 관리 - 메뉴권한 {menu_permission_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div> {/* 여기 버튼 클래스 다른거 해뒀는데 일단 기본 "등록"으로 해둘것 */}
              <H.admin_addBtn
                onClick={()=>{
                  menu_permission_check();
                }}
              >
                {menu_permission_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.admin_labelBox>
              <label htmlFor='menu_permission_name'>메뉴권한명<span> * </span></label>
              <input 
                type='text' 
                id = 'menu_permission_name' 
                maxLength={50} 
                onChange={(event_data)=>{
                  setMenuPermissionName(event_data.target.value);
                }} 
                placeholder = "메뉴권한명을 입력해 주세요."
                value={menuPermissionName}
              />
              <H.admin_errorBox className="marginLeft150">{menuPermissionNameError}</H.admin_errorBox>
            </H.admin_labelBox>

            {menu_permission_ID_param !== null &&  
            <H.admin_labelBox>
              <label htmlFor='menu_permission_status'>사용 여부</label>
              <select
                value={menuPermissionStatus}
                onChange={(event_data)=>{
                  setMenuPermissionStatus(event_data.target.value);
                }}
              >
                <option value='1'>사용</option>
                <option value='2'>미사용</option>
              </select>
              
            </H.admin_labelBox>
            }

            <H.admin_menuPermissionBox>
              <div>
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_1' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box_all(e, ['1', '1-1', '1-2']);
                  }}
                  checked={check_box_bool_judgement('1')}
                />
                <label htmlFor='checkbox_menu_1'>시스템 관리</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_1_1' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '1', '1-1');
                  }}
                  checked={check_box_bool_judgement('1-1')}
                />
                <label htmlFor='checkbox_menu_1_1'>메뉴권한 관리</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_1_2' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '1', '1-2');
                  }}
                  checked={check_box_bool_judgement('1-2')}
                />
                <label htmlFor='checkbox_menu_1_2'>사용자 관리</label>
              </div>
            </H.admin_menuPermissionBox>

            <H.admin_menuPermissionBox>
              <div>
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_2' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box_all(e, ['2', '2-1', '2-2', '2-3']);
                  }}
                  checked={check_box_bool_judgement('2')}
                />
                <label htmlFor='checkbox_menu_2'>메인화면 관리</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_2_1' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '2', '2-1');
                  }}
                  checked={check_box_bool_judgement('2-1')}
                />
                <label htmlFor='checkbox_menu_2_1'>롤링 배너</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_2_2' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '2', '2-2');
                  }}
                  checked={check_box_bool_judgement('2-2')}
                />
                <label htmlFor='checkbox_menu_2_2'>콘텐츠아카이브보다 배너</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_2_3' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '2', '2-3');
                  }}
                  checked={check_box_bool_judgement('2-3')}
                />
                <label htmlFor='checkbox_menu_2_3'>대형 이미지 배너</label>
              </div>
            </H.admin_menuPermissionBox>

            <H.admin_menuPermissionBox>
              <div>
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_3' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box_all(e, ['3', '3-1', '3-2']);
                  }}
                  checked={check_box_bool_judgement('3')}
                />
                <label htmlFor='checkbox_menu_3'>CAB 관리</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_3_1' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '3', '3-1');
                  }}
                  checked={check_box_bool_judgement('3-1')}
                />
                <label htmlFor='checkbox_menu_3_1'>회사소개</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_3_2' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '3', '3-2');
                  }}
                  checked={check_box_bool_judgement('3-2')}
                />
                <label htmlFor='checkbox_menu_3_2'>경영이념</label>
              </div>
            </H.admin_menuPermissionBox>

            <H.admin_menuPermissionBox>
              <div>
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_4' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box_all(e, ['4', '4-1', '4-2', '4-3', '4-4', '4-5', '4-6', '4-7', '4-8']);
                  }}
                  checked={check_box_bool_judgement('4')}
                />
                <label htmlFor='checkbox_menu_4'>서비스 관리</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_4_1' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '4', '4-1');
                  }}
                  checked={check_box_bool_judgement('4-1')}
                />
                <label htmlFor='checkbox_menu_4_1'>컨설팅</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_4_2' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '4', '4-2');
                  }}
                  checked={check_box_bool_judgement('4-2')}
                />
                <label htmlFor='checkbox_menu_4_2'>브랜딩</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_4_3' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '4', '4-3');
                  }}
                  checked={check_box_bool_judgement('4-3')}
                />
                <label htmlFor='checkbox_menu_4_3'>마케팅</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_4_4' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '4', '4-4');
                  }}
                  checked={check_box_bool_judgement('4-4')}
                />
                <label htmlFor='checkbox_menu_4_4'>콘텐츠 제작</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_4_5' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '4', '4-5');
                  }}
                  checked={check_box_bool_judgement('4-5')}
                />
                <label htmlFor='checkbox_menu_4_5'>PPL 광고</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_4_6' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '4', '4-6');
                  }}
                  checked={check_box_bool_judgement('4-6')}
                />
                <label htmlFor='checkbox_menu_4_6'>사이트 제작</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_4_7' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '4', '4-7');
                  }}
                  checked={check_box_bool_judgement('4-7')}
                />
                <label htmlFor='checkbox_menu_4_7'>아카이빙 사업</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_4_8' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '4', '4-8');
                  }}
                  checked={check_box_bool_judgement('4-8')}
                />
                <label htmlFor='checkbox_menu_4_8'>사이트 제작</label>
              </div>
            </H.admin_menuPermissionBox>

            <H.admin_menuPermissionBox>
              <div>
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_5' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box_all(e, ['5', '5-1', '5-2']);
                  }}
                  checked={check_box_bool_judgement('5')}
                />
                <label htmlFor='checkbox_menu_5'>메뉴 관리</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_5_1' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '5', '5-1');
                  }}
                  checked={check_box_bool_judgement('5-1')}
                />
                <label htmlFor='checkbox_menu_5_1'>WHY? CAB</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_5_2' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '5', '5-2');
                  }}
                  checked={check_box_bool_judgement('5-2')}
                />
                <label htmlFor='checkbox_menu_5_2'>포토폴리오</label>
              </div>
            </H.admin_menuPermissionBox>
            
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

export default MenuPermissionManagementAdd;
