import Axios from "axios";

//테스트서버
/* const base_url = "http://localhost:6767/";
export const base_url_export = "http://localhost:6767/"; */

//운영서버
const base_url = "https://www.cabplanet.co.kr:6767/";
export const base_url_export = "https://www.cabplanet.co.kr:6767/";

export const login_judgement = async (user_login_ID, user_password) => {
  let data = new FormData();
  data.append('user_login_ID',user_login_ID);
  data.append('user_password',user_password);
  try{
      const response = await Axios.post(
          `${base_url}login/login_judgement`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_session_data_admin = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}login/get_session_data_admin`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_menu_permission = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}system/get_menu_permission`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_menu_permission_one = async (
  menu_permission_ID
) => {
  let data = new FormData();
  data.append('menu_permission_ID',menu_permission_ID);
  try{
      const response = await Axios.post(
          `${base_url}system/get_menu_permission_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_menu_permission_status_1 = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}system/get_menu_permission_status_1`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const insert_menu_permission = async (
  menu_permission_name,
  menu_permission_status,
  menu_permission_array,
) => {
  let data = new FormData();
  data.append('menu_permission_name',menu_permission_name);
  data.append('menu_permission_status',menu_permission_status);
  data.append('menu_permission_array',menu_permission_array);
  try{
      const response = await Axios.post(
          `${base_url}system/insert_menu_permission`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_menu_permission = async (
  menu_permission_ID,
  menu_permission_name,
  menu_permission_status,
  menu_permission_array,
) => {
  let data = new FormData();
  data.append('menu_permission_ID',menu_permission_ID);
  data.append('menu_permission_name',menu_permission_name);
  data.append('menu_permission_status',menu_permission_status);
  data.append('menu_permission_array',menu_permission_array);
  try{
      const response = await Axios.post(
          `${base_url}system/update_menu_permission`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_user = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}system/get_user`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_user_one = async (
  user_ID
) => {
  let data = new FormData();
  data.append('user_ID',user_ID);
  try{
      const response = await Axios.post(
          `${base_url}system/get_user_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const insert_user = async (
  menu_permission_ID_use_user,
  user_login_ID,
  user_password,
  user_name,
  user_status,
) => {
  let data = new FormData();
  data.append('menu_permission_ID_use_user',menu_permission_ID_use_user);
  data.append('user_login_ID',user_login_ID);
  data.append('user_password',user_password);
  data.append('user_name',user_name);
  data.append('user_status',user_status);
  try{
      const response = await Axios.post(
          `${base_url}system/insert_user`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_user = async (
  user_ID,
  menu_permission_ID_use_user,
  user_login_ID,
  user_password,
  user_name,
  user_status,
) => {
  let data = new FormData();
  data.append('user_ID',user_ID);
  data.append('menu_permission_ID_use_user',menu_permission_ID_use_user);
  data.append('user_login_ID',user_login_ID);
  data.append('user_password',user_password);
  data.append('user_name',user_name);
  data.append('user_status',user_status);
  try{
      const response = await Axios.post(
          `${base_url}system/update_user`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_rolling_banner = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_rolling_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_rolling_banner_status_1 = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_rolling_banner_status_1`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_rolling_banner_order = async (
  rolling_banner_order_array,
) => {
  let data = new FormData();
  data.append('rolling_banner_order_array',JSON.stringify(rolling_banner_order_array));
  try{
      const response = await Axios.post(
          `${base_url}main/update_rolling_banner_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const insert_rolling_banner = async (
  rolling_banner_name,
  rolling_banner_image,
  rolling_banner_image_mobile,
  rolling_banner_link,
) => {
  let data = new FormData();
  data.append('rolling_banner_name',rolling_banner_name);
  data.append('rolling_banner_image',rolling_banner_image);
  data.append('rolling_banner_image_mobile',rolling_banner_image_mobile);
  data.append('rolling_banner_link',rolling_banner_link);
  try{
      const response = await Axios.post(
          `${base_url}main/insert_rolling_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_rolling_banner = async (
  rolling_banner_ID,
  rolling_banner_name,
  rolling_banner_image,
  rolling_banner_image_mobile,
  rolling_banner_link,
  rolling_banner_status,
) => {
  let data = new FormData();
  data.append('rolling_banner_ID',rolling_banner_ID);
  data.append('rolling_banner_name',rolling_banner_name);
  data.append('rolling_banner_image',rolling_banner_image);
  data.append('rolling_banner_image_mobile',rolling_banner_image_mobile);
  data.append('rolling_banner_link',rolling_banner_link);
  data.append('rolling_banner_status',rolling_banner_status);
  try{
      const response = await Axios.post(
          `${base_url}main/update_rolling_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const delete_rolling_banner = async (
  rolling_banner_ID_array,
) => {
  let data = new FormData();
  data.append('rolling_banner_ID_array',rolling_banner_ID_array);
  try{
      const response = await Axios.post(
          `${base_url}main/delete_rolling_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_rolling_banner_one = async (
  rolling_banner_ID
) => {
  let data = new FormData();
  data.append('rolling_banner_ID',rolling_banner_ID);
  try{
      const response = await Axios.post(
          `${base_url}main/get_rolling_banner_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_small_banner = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_small_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_small_banner_order = async (
  small_banner_order_array,
) => {
  let data = new FormData();
  data.append('small_banner_order_array',JSON.stringify(small_banner_order_array));
  try{
      const response = await Axios.post(
          `${base_url}main/update_small_banner_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const insert_small_banner = async (
  small_banner_name,
  small_banner_image,
  small_banner_link,
) => {
  let data = new FormData();
  data.append('small_banner_name',small_banner_name);
  data.append('small_banner_image',small_banner_image);
  data.append('small_banner_link',small_banner_link);
  try{
      const response = await Axios.post(
          `${base_url}main/insert_small_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_small_banner = async (
  small_banner_ID,
  small_banner_name,
  small_banner_image,
  small_banner_link,
) => {
  let data = new FormData();
  data.append('small_banner_ID',small_banner_ID);
  data.append('small_banner_name',small_banner_name);
  data.append('small_banner_image',small_banner_image);
  data.append('small_banner_link',small_banner_link);
  try{
      const response = await Axios.post(
          `${base_url}main/update_small_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const delete_small_banner = async (
  small_banner_ID_array,
) => {
  let data = new FormData();
  data.append('small_banner_ID_array',small_banner_ID_array);
  try{
      const response = await Axios.post(
          `${base_url}main/delete_small_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_small_banner_one = async (
  small_banner_ID
) => {
  let data = new FormData();
  data.append('small_banner_ID',small_banner_ID);
  try{
      const response = await Axios.post(
          `${base_url}main/get_small_banner_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_fix_banner = async (
  fix_banner_ID
) => {
  let data = new FormData();
  data.append('fix_banner_ID',fix_banner_ID);
  try{
      const response = await Axios.post(
          `${base_url}main/get_fix_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_fix_banner = async (
  fix_banner_ID,
  fix_banner_image,
  fix_banner_image_mobile,
  fix_banner_link,
  fix_banner_title,
) => {
  let data = new FormData();
  data.append('fix_banner_ID',fix_banner_ID);
  data.append('fix_banner_image',fix_banner_image);
  data.append('fix_banner_image_mobile',fix_banner_image_mobile);
  data.append('fix_banner_link',fix_banner_link);
  data.append('fix_banner_title',fix_banner_title);
  try{
      const response = await Axios.post(
          `${base_url}main/update_fix_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const insert_editor_image = async (
  editor_file
) => {
  let data = new FormData();
  data.append('editor_file',editor_file.blob());
  try{
      const response = await Axios.post(
          `${base_url}editor/insert_editor_image`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_editor_data = async (
  editor_division
) => {
  let data = new FormData();
  data.append('editor_division',editor_division);
  try{
      const response = await Axios.post(
          `${base_url}editor/get_editor_data`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_editor_data = async (
  editor_division,
  editor_content,
  editor_content_mobile,
) => {
  let data = new FormData();
  data.append('editor_division',editor_division);
  data.append('editor_content',editor_content);
  data.append('editor_content_mobile',editor_content_mobile);
  try{
      const response = await Axios.post(
          `${base_url}editor/update_editor_data`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}




export const get_portfolio = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}menu/get_portfolio`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_portfolio_status = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}menu/get_portfolio_status`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_portfolio_order = async (
  portfolio_order_array,
) => {
  let data = new FormData();
  data.append('portfolio_order_array',JSON.stringify(portfolio_order_array));
  try{
      const response = await Axios.post(
          `${base_url}menu/update_portfolio_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const insert_portfolio = async (
  portfolio_title,
  portfolio_sub_title,
  portfolio_main_image,
  portfolio_editor,
  portfolio_editor_mobile,
) => {

  let data = new FormData();
  data.append('portfolio_title',portfolio_title);
  data.append('portfolio_sub_title',portfolio_sub_title);
  data.append('portfolio_main_image',portfolio_main_image);
  data.append('portfolio_editor',portfolio_editor);
  data.append('portfolio_editor_mobile',portfolio_editor_mobile);

  try{
      const response = await Axios.post(
          `${base_url}menu/insert_portfolio`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_portfolio = async (
  portfolio_ID,
  portfolio_title,
  portfolio_sub_title,
  portfolio_main_image,
  portfolio_status,
  portfolio_editor,
  portfolio_editor_mobile,
) => {

  let data = new FormData();
  data.append('portfolio_ID',portfolio_ID);
  data.append('portfolio_title',portfolio_title);
  data.append('portfolio_sub_title',portfolio_sub_title);
  data.append('portfolio_main_image',portfolio_main_image);
  data.append('portfolio_status',portfolio_status);
  data.append('portfolio_editor',portfolio_editor);
  data.append('portfolio_editor_mobile',portfolio_editor_mobile);

  try{
      const response = await Axios.post(
          `${base_url}menu/update_portfolio`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const delete_portfolio = async (
  portfolio_ID_array,
) => {

  let data = new FormData();
  data.append('portfolio_ID_array',portfolio_ID_array);

  try{
      const response = await Axios.post(
          `${base_url}menu/delete_portfolio`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_portfolio_one = async (
  portfolio_ID
) => {
  let data = new FormData();
  data.append('portfolio_ID',portfolio_ID);
  try{
      const response = await Axios.post(
          `${base_url}menu/get_portfolio_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}
