import React, {useState, useEffect, useRef} from 'react';
import AdminHeader from '../Section/AdminHeader';
import {
  insert_portfolio,
  get_portfolio_one,
  base_url_export,
  update_portfolio,
} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';
import no_image from '../../Images/Etc/no_image.gif';
import { Editor } from '@tinymce/tinymce-react';
import {
  editor_init_data,
  editor_init_data_mo,
} from "../../Variable";


export function AdminPortfolioManagementAdd() {  
  const params = new URLSearchParams(window.location.search);
  const portfolio_ID_param = params.get("portfolio_ID");
  
  const editorRef = useRef(null);

  const [portfolioTitle, setPortfolioTitle] = useState('');
  const [portfolioSubTitle, setPortfolioSubTitle] = useState('');
  const [portfolioMainImage, setPortfolioMainImage] = useState('');
  const [portfolioStatus, setPortfolioStatus] = useState('1');
  const [htmlString, setHtmlString] = useState("");
  const [initialValue, setInitialValue] = useState("");
  const [htmlStringMobile, setHtmlStringMobile] = useState("");
  const [initialValueMobile, setInitialValueMobile] = useState("");
  const [portfolioMainImageURL, setPortfolioMainImageURL] = useState('');
  const [portfolioTitleError, setPortfolioTitleError] = useState('');
  const [portfolioSubTitleError, setPortfolioSubTitleError] = useState('');
  const [portfolioMainImageError, setPortfolioMainImageError] = useState('');

  const portfolio_check = () =>{
    let continue_add = true;
    if(portfolioTitle === ''){setPortfolioTitleError('메인타이틀을 등록해 주세요.'); continue_add = false;}else{setPortfolioTitleError('');}
    if(portfolioSubTitle === ''){setPortfolioSubTitleError('서브타이틀을 등록해 주세요.'); continue_add = false;}else{setPortfolioSubTitleError('');}
    if(portfolioMainImageURL === ''){setPortfolioMainImageError('포토폴리오 메인이미지를 등록해 주세요.'); continue_add = false;}else{setPortfolioMainImageError('');}
    if(continue_add){
      if(portfolio_ID_param === null){
        insert_portfolio(
          portfolioTitle,
          portfolioSubTitle,
          portfolioMainImage,
          htmlString.replace( /'/gi, '"'),
          htmlStringMobile.replace( /'/gi, '"'),
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/AdminPortfolioManagement";
        });
      }else{
        update_portfolio(
          portfolio_ID_param,
          portfolioTitle,
          portfolioSubTitle,
          portfolioMainImage,
          portfolioStatus,
          htmlString.replace( /'/gi, '"'),
          htmlStringMobile.replace( /'/gi, '"'),
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/AdminPortfolioManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해 주세요.');
    }
  }

  useEffect(() => {
    if(portfolio_ID_param !== null){
      get_portfolio_one(portfolio_ID_param).then((result) => {
        setPortfolioTitle(result[0].portfolio_title);
        setPortfolioSubTitle(result[0].portfolio_sub_title);
        setInitialValue(result[0].portfolio_editor);
        setInitialValueMobile(result[0].portfolio_editor_mobile);
        setPortfolioStatus(result[0].portfolio_status);
        setPortfolioMainImageURL(base_url_export + result[0].portfolio_main_image);
      })
    }
  },[portfolio_ID_param]);

  return (
    <H.admin_MainWrap>
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>메뉴 관리 - 포토폴리오 {portfolio_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div>
              <H.admin_addBtn
              onClick={()=>{portfolio_check();}}>
              {portfolio_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>



        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>

            <H.admin_labelBox>
              <label htmlFor='portfolio_title'>메인 타이틀<span> * </span></label>
              <input 
                type='text' 
                id = 'portfolio_title' 
                maxLength={50} 
                onChange={(event_data)=>{
                  setPortfolioTitle(event_data.target.value);
                }} 
                placeholder = "메인 타이틀을 입력해 주세요(최대 50자)"
                value={portfolioTitle}
              />
              <H.admin_errorBox className="marginLeft150">{portfolioTitleError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='portfolio_sub_title'>서브 타이틀<span> * </span></label>
              <input 
                type='text' 
                id = 'portfolio_sub_title' 
                maxLength={70} 
                onChange={(event_data)=>{
                  setPortfolioSubTitle(event_data.target.value);
                }} 
                placeholder = "서브 타이틀을 입력해 주세요(최대 70자)"
                value={portfolioSubTitle}
              />
              <H.admin_errorBox className="marginLeft150">{portfolioSubTitleError}</H.admin_errorBox>
            </H.admin_labelBox>

            {portfolio_ID_param !== null &&  
            <H.admin_labelBox>
              <label htmlFor='portfolio_status'>사용 여부</label>
              <select
                value={portfolioStatus}
                onChange={(event_data)=>{
                  setPortfolioStatus(event_data.target.value);
                }}
              >
                <option value='1'>사용</option>
                <option value='2'>미사용</option>
              </select>
              
            </H.admin_labelBox>
            }

            <H.admin_labelImg>
              <p className="labelImgTit">포토폴리오 메인 이미지 </p>
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                    <p>메인 이미지<span> * </span></p>
                    <p className="labelImgInfo">사이즈 630 x 630px</p>
                    <label htmlFor='portfolio_main_image'>
                      {portfolio_ID_param === null ? ' 추가 ' : ' 변경 '}
                    </label>
                  </div>
                  <input 
                    className='display_none'
                    type='file' 
                    id = 'portfolio_main_image'                          
                    accept='image/gif, image/jpeg, image/png'
                    onChange={(event_data)=>{
                      if(event_data.target.files[0] !== undefined){
                        setPortfolioMainImage(event_data.target.files[0]);
                        const reader = new FileReader();
                        reader.readAsDataURL(event_data.target.files[0]);
                        reader.onloadend = () => {
                          setPortfolioMainImageURL(reader.result);
                        };
                      }
                    }} 
                  />
                  <img className="admin_newsImg" 
                    src={
                      portfolioMainImageURL === '' ?
                      no_image : portfolioMainImageURL
                    } 
                    alt="포토폴리오 메인이미지"
                  />
                </div>
                <H.admin_errorBox className="marginLeft150">{portfolioMainImageError}</H.admin_errorBox>
              </div>
            </H.admin_labelImg>


            <H.listInfoText className="marginTop50">
              <label htmlFor='portfolio_editor' className="listInfoTextBold">
                포토폴리오 세부내용 사용 방법 안내<span> * </span>
              </label>
              <p>1. 보안 관계로 ''(작은따옴표)는 사용할 수 없습니다.</p>
              <p>2. ''(작은따옴표)를 사용한 경우에는 자동으로 ""(큰따옴표)로 변경됩니다.</p>
              <p>3. 이미지 파일명은 반드시 영문명으로 지정해야 오류가 나지 않습니다.</p>
              <p>4. 서식 적용이 되지 않을 경우 "서식 - 서식지우기" 아이콘을 활용하여 서식을 초기화 한 후 작성해주세요.</p>
              <p>5. 이미지 등록 시 PC는 최대 가로길이 1200px로 맞춰야 이미지 잘림 없이 볼 수 있습니다.</p>
            </H.listInfoText>
            <H.admin_editorBoxTit>
              PC용 에디터
              <span>(스크린 최소 사이즈 기준 1200px)</span>
            </H.admin_editorBoxTit>
            <Editor
              apiKey='kzj2jdtduql44vtgurqnrj4otlx3bkvkiznzk18tvm41yrcj'
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={initialValue}
              init={editor_init_data}
              onEditorChange={(newValue, editor) => {
                setHtmlString(newValue);
              }}
            />
            <H.admin_editorBoxTit>
              모바일용 에디터
              <span>(스크린 최소 사이즈 기준 480px)</span>
            </H.admin_editorBoxTit>
            <Editor
              apiKey='kzj2jdtduql44vtgurqnrj4otlx3bkvkiznzk18tvm41yrcj'
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={initialValueMobile}
              init={editor_init_data_mo}
              onEditorChange={(newValue, editor) => {
                setHtmlStringMobile(newValue);
              }}
            />
        
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>

      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

export default AdminPortfolioManagementAdd;
