import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

/* 홈페이지 영역 */
import HomepageMain from './Homepage/Main/Main';
import Editor from './Homepage/Editor/Editor';
import Portfolio from './Homepage/Menu/Portfolio';
import PortfolioDetail from './Homepage/Menu/PortfolioDetail';

/* 관리자 영역 */
import AdminLogin from './Admin/Login/AdminLogin';
import AdminMain from './Admin/Section/AdminMain';
import MenuPermissionManagement from './Admin/SystemManagement/MenuPermissionManagement';
import MenuPermissionManagementAdd from './Admin/SystemManagement/MenuPermissionManagementAdd';
import UserManagement from './Admin/SystemManagement/UserManagement';
import UserManagementAdd from './Admin/SystemManagement/UserManagementAdd';
import AdminRollingBannerManagement from './Admin/MainManagement/AdminRollingBannerManagement';
import AdminRollingBannerManagementAdd from './Admin/MainManagement/AdminRollingBannerManagementAdd';
import AdminSmallBannerManagement from './Admin/MainManagement/AdminSmallBannerManagement';
import AdminSmallBannerManagementAdd from './Admin/MainManagement/AdminSmallBannerManagementAdd';
import AdminFixBannerManagement from './Admin/MainManagement/AdminFixBannerManagement';
import AdminEditorManagement from './Admin/EditorManagement/AdminEditorManagement';
import AdminPortfolioManagement from './Admin/MenuManagement/AdminPortfolioManagement';
import AdminPortfolioManagementAdd from './Admin/MenuManagement/AdminPortfolioManagementAdd';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      {/* 홈페이지 영역*/}
      {/* 경로를 /라고 치면 HomepageMain으로 이동함 */}
      <Route path="/" element={<HomepageMain />} />{/* 홈페이지 메인화면 */}
      <Route path="/Editor" element={<Editor />} />{/* 홈페이지 포토폴리오 화면 */}
      <Route path="/Portfolio" element={<Portfolio />} />{/* 홈페이지 포토폴리오 화면 */}
      <Route path="/PortfolioDetail" element={<PortfolioDetail />} />{/* 홈페이지 포토폴리오 상세화면 */}

      {/* 관리자페이지 영역*/}
      <Route path="/AdminLogin" element={<AdminLogin />} /> {/* 관리자페이지 로그인화면 */}
      <Route path="/AdminMain" element={<AdminMain />} /> {/* 관리자페이지 메인화면 */}
      <Route path="/MenuPermissionManagement" element={<MenuPermissionManagement />} /> {/* 관리자페이지 메뉴권한 관리 */}
      <Route path="/MenuPermissionManagementAdd" element={<MenuPermissionManagementAdd />} /> {/* 관리자페이지 메뉴권한 등륵/수정 관리 */}
      <Route path="/UserManagement" element={<UserManagement />} /> {/* 관리자페이지 사용자 관리 */}
      <Route path="/UserManagementAdd" element={<UserManagementAdd />} /> {/* 관리자페이지 사용자 등륵/수정 관리 */}
      <Route path="/AdminRollingBannerManagement" element={<AdminRollingBannerManagement />} /> {/* 관리자페이지 롤링 배너 관리 */}
      <Route path="/AdminRollingBannerManagementAdd" element={<AdminRollingBannerManagementAdd />} /> {/* 관리자페이지 롤링 배너등록/수정 메뉴 */}
      <Route path="/AdminSmallBannerManagement" element={<AdminSmallBannerManagement />} /> {/* 관리자페이지 소형 이미지 배너 관리 */}
      <Route path="/AdminSmallBannerManagementAdd" element={<AdminSmallBannerManagementAdd />} /> {/* 관리자페이지 소형 이미지 배너등록/수정 메뉴 */}
      <Route path="/AdminFixBannerManagement" element={<AdminFixBannerManagement />} />{/* 관리자페이지 대형 이미지 배너 관리 */}
      <Route path="/AdminEditorManagement" element={<AdminEditorManagement />} />{/* 관리자페이지 에디터 관리 */}
      <Route path="/AdminPortfolioManagement" element={<AdminPortfolioManagement />} /> {/* 관리자페이지 포토폴리오 관리 */}
      <Route path="/AdminPortfolioManagementAdd" element={<AdminPortfolioManagementAdd />} /> {/* 관리자페이지 포토폴리오 등륵/수정 관리 */}
    </Routes>
  </BrowserRouter>
);
