import React, {useState, useEffect} from 'react';
import AdminHeader from '../Section/AdminHeader';
import {
  get_fix_banner,
  base_url_export,
  update_fix_banner,
} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';
import no_image from '../../Images/Etc/no_image.gif';


export function AdminFixBannerManagement() {  

  const [fixBannerImage, setFixBannerImage] = useState('');
  const [fixBannerImageMobie, setFixBannerImageMobie] = useState('');
  const [fixBannerTitle, setFixBannerTitle] = useState('');
  const [fixBannerLink, setFixBannerLink] = useState('');

  const [fixBannerImageURL, setFixBannerImageURL] = useState('');
  const [fixBannerImageMobieURL, setFixBannerImageMobieURL] = useState('');

  const [fixBannerImageError, setFixBannerImageError] = useState('');
  const [fixBannerImageMobieError, setFixBannerImageMobieError] = useState('');
  const [fixBannerTitleError, setFixBannerTitleError] = useState('');


  //컨트롤 함수
  const fix_banner_check = () =>{
    let continue_add = true;
    if(fixBannerImageURL === ''){setFixBannerImageError('배너 PC용 이미지를 등록해 주세요.'); continue_add = false;}else{setFixBannerImageError('');}
    if(fixBannerImageMobieURL === ''){setFixBannerImageMobieError('배너 모바일용 이미지를 등록해 주세요.'); continue_add = false;}else{setFixBannerImageMobieError('');}
    if(fixBannerTitle === ''){setFixBannerTitleError('배너타이틀을 등록해 주세요.'); continue_add = false;}else{setFixBannerTitleError('');}

    if(continue_add){
      update_fix_banner(
        '1',
        fixBannerImage,
        fixBannerImageMobie,
        fixBannerLink,
        fixBannerTitle,
      ).then(()=>{
        alert('수정이 완료되었습니다.');
        window.location.href = "/AdminFixBannerManagement";
      });
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  useEffect(() => {
    get_fix_banner('1').then((result) => {
      setFixBannerLink(result[0].fix_banner_link);
      setFixBannerTitle(result[0].fix_banner_title);
      setFixBannerImageURL(base_url_export + result[0].fix_banner_image);
      setFixBannerImageMobieURL(base_url_export + result[0].fix_banner_image_mobile);
    })
  },[]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>메인화면 관리 - 대형 이미지 배너 수정</p>
            <div> {/* 여기 버튼 클래스 다른거 해뒀는데 일단 기본 "등록"으로 해둘것 */}
              <H.admin_changeBtn
                onClick={()=>{
                  fix_banner_check();
                }}
              >
                수정
              </H.admin_changeBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>


            <H.admin_labelBox>
              <label htmlFor='fix_banner_title'>배너타이틀<span> * </span></label>
              <input 
                type='text' 
                id = 'fix_banner_title' 
                maxLength={50} 
                onChange={(event_data)=>{
                  setFixBannerTitle(event_data.target.value);
                }} 
                placeholder = "배너타이틀을 입력해주세요."
                value={fixBannerTitle}
              />
              <H.admin_errorBox className="marginLeft150">{fixBannerTitleError}</H.admin_errorBox>
            </H.admin_labelBox>
            
            <H.admin_labelBox>
              <label htmlFor='fix_banner_link'>배너링크</label>
              <input 
                type='text' 
                id = 'fix_banner_link' 
                maxLength={300} 
                onChange={(event_data)=>{
                  setFixBannerLink(event_data.target.value);
                }} 
                placeholder = "배너 클릭 시 이동을 원하는 링크 주소를 작성해 주세요. 없으면 생략 가능 합니다."
                value={fixBannerLink}
              />
            </H.admin_labelBox>
    
            <H.admin_labelImg>
              <p className="labelImgTit">배너이미지</p>
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                    <p>PC용 배너이미지<span> * </span></p>
                    <p className="labelImgInfo">사이즈 1120 x 600px</p>
                    <label htmlFor='fix_banner_image'>
                      변경
                    </label>
                  </div>
                  <input 
                    className='display_none'
                    type='file' 
                    id = 'fix_banner_image'                           
                    accept='image/gif, image/jpeg, image/png'
                    onChange={(event_data)=>{
                      if(event_data.target.files[0] !== undefined){
                        setFixBannerImage(event_data.target.files[0]);
                        const reader = new FileReader();
                        reader.readAsDataURL(event_data.target.files[0]);
                        reader.onloadend = () => {
                          setFixBannerImageURL(reader.result);
                          };
                      }
                    }} 
                  />
                  <img className="admin_ourstoryPC"
                    src={
                      fixBannerImageURL === '' ?
                      no_image : fixBannerImageURL
                    } 
                    alt="배너이미지"
                  />
                </div>
                <H.admin_errorBox className="marginLeft150">{fixBannerImageError}</H.admin_errorBox>
              </div>
              
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                      <p>모바일용 배너이미지<span> * </span></p>
                      <p className="labelImgInfo">사이즈 630 x 600px</p>
                      <p className="labelImgInfo">모바일 가로 360 보임</p>
                      <label htmlFor='fix_banner_image_mobile'>
                        변경
                      </label>
                    </div>
                    <input 
                      className='display_none'
                      type='file' 
                      id='fix_banner_image_mobile'                      
                      accept='image/gif, image/jpeg, image/png'
                      onChange={(event_data)=>{
                        if(event_data.target.files[0] !== undefined){
                          setFixBannerImageMobie(event_data.target.files[0]);
                          const reader = new FileReader();
                          reader.readAsDataURL(event_data.target.files[0]);
                          reader.onloadend = () => {
                            setFixBannerImageMobieURL(reader.result);
                          };
                        }
                      }} 
                    />
                    <img className="admin_ourstoryMo"
                      src={
                        fixBannerImageMobieURL === '' ?
                        no_image : fixBannerImageMobieURL
                      } 
                      alt="배너 모바일 이미지"
                    />
                </div>
                <H.admin_errorBox className="marginLeft150">{fixBannerImageMobieError}</H.admin_errorBox>
              </div>
            </H.admin_labelImg>
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

export default AdminFixBannerManagement;
