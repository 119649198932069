import React, {useEffect, useState} from 'react';
import {get_session_data_admin} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';
import admin_sideMenuLogo from '../../Images/cab_logo_w.png'

export function AdminHeader() {  
  const [menuPermissionArray, setMenuPermissionArray] = useState('');

  useEffect(() => {
    get_session_data_admin().then((result)=>{
      if(result.user_ID === '' || result.user_ID === undefined){
        alert('로그인 시간이 만료되었습니다.');
        window.location.href = "/AdminLogin";
      }else{
        setMenuPermissionArray(result.menu_permission_array);
      }
    })
  },[]);
  
  return (

    <H.admin_HeaderWrap>
      <H.admin_sideMenuBox>
        <H.admin_sideMenuLogo>
          <a href='/AdminMain'>
            <img src = {admin_sideMenuLogo} alt="콘텐츠아카이브보다 로고"></img>
          </a>
        </H.admin_sideMenuLogo>

        {menuPermissionArray.includes("1") && 
          <>
            <H.admin_MenuTreeTop>시스템 관리</H.admin_MenuTreeTop>
            {menuPermissionArray.includes("1-1") && 
              <H.admin_MenuTreeMiddle>
                <a href='/MenuPermissionManagement'>메뉴권한 관리</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("1-2") && 
              <H.admin_MenuTreeMiddle>
                <a href='/UserManagement'>사용자 관리</a>
              </H.admin_MenuTreeMiddle>
            }
          </>
        }

        {menuPermissionArray.includes("2") && 
          <>
            <H.admin_MenuTreeTop>메인화면 관리</H.admin_MenuTreeTop>
            {menuPermissionArray.includes("2-1") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminRollingBannerManagement'>롤링 배너</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("2-2") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminSmallBannerManagement'>콘텐츠아카이브보다 배너</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("2-3") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminFixBannerManagement'>대형 이미지 관리</a>
              </H.admin_MenuTreeMiddle>
            }
          </>
        }

        {menuPermissionArray.includes("3") && 
          <>
            <H.admin_MenuTreeTop>CAB 관리</H.admin_MenuTreeTop>
            {menuPermissionArray.includes("3-1") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminEditorManagement?editor_division=1'>회사소개</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("3-2") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminEditorManagement?editor_division=2'>경영이념</a>
              </H.admin_MenuTreeMiddle>
            }
          </>
        }

        {menuPermissionArray.includes("4") && 
          <>
            <H.admin_MenuTreeTop>서비스 관리</H.admin_MenuTreeTop>
            {menuPermissionArray.includes("4-1") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminEditorManagement?editor_division=3'>컨설팅</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("4-2") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminEditorManagement?editor_division=4'>브랜딩</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("4-3") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminEditorManagement?editor_division=5'>마케팅</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("4-4") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminEditorManagement?editor_division=6'>콘텐츠 제작</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("4-5") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminEditorManagement?editor_division=7'>PPL 광고</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("4-6") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminEditorManagement?editor_division=8'>사이트 제작</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("4-7") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminEditorManagement?editor_division=9'>아카이빙 사업</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("4-8") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminEditorManagement?editor_division=10'>PG 사업</a>
              </H.admin_MenuTreeMiddle>
            }
          </>
        }

        {menuPermissionArray.includes("5") && 
          <>
            <H.admin_MenuTreeTop>메뉴 관리</H.admin_MenuTreeTop>
            {menuPermissionArray.includes("5-1") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminEditorManagement?editor_division=11'>WHY? CAB</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("5-2") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminPortfolioManagement'>포토폴리오</a>
              </H.admin_MenuTreeMiddle>
            }
          </>
        }

      </H.admin_sideMenuBox>
    </H.admin_HeaderWrap>
  );
}

//실제사용하는 것은 아니고 샘플입니다.
export function AdminHeader2() {  
  return (
    <div>
      <p>메뉴</p>
      <div>
        <p>메인화면 관리</p>
        <div>
          <a href='/AdminRollingBannerManagement'>롤링 배너관리</a>
        </div>
      </div>
    </div>
  );
}

//export default AdminHeader라는 것은 사실 하나의 컴포넌트에 여러개의 함수를 export 시킬 수 있는데
//default라는것은 기본값으로 가져간다는 것인데 위에 AdminHeader와 AdminHeader2중에 AdminHeader를 호출하면 
//AdminHeader를 기본값으로 준다는 것입니다. 근데 보통 하나의 컴포넌트안에 하나의 함수를 사용하는게 
//유지보수 차원에 편하고 좋아요 만약 2개를 만든다면 파일을 하나 만드는게 훨씬 편한데
//뭐 개발자마다 성향이 다른거라 뭐가 맞다고 표현하기 힘드네요
export default AdminHeader;
