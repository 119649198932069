import React from 'react';
//src/Admin/Section/AdminHeader파일안에 있는 
//AdminHaeder 컴포넌트를 가져온 것이고
//컴포넌트는 AdminHeader파일에 자세하게 설명해났습니다.
//즉 AdminHeader파일에 있는 소스를 가져와서
//메인화면에 넣는 다는 것이고 사용 방법은 아래 <AdminHeader/>라고 
//import해온 값을 태그걸어서 넣으면 적용이 됩니다.
import AdminHeader from './AdminHeader';

import * as H from '../../Styles/StyleComponentHan';


export function AdminMain() {  
  return (
    //CSS는 전문가이기 때문에 이야기는 안하겠습니다.
    //CSS파일은 public/css 폴더에 있습니다.
    //스타일 컴포넌트를 연습해보시는 것도 좋은 방법인 것 같습니다.
    //스타일 컴포넌트 사용법은 솔레노어 웹 보고 하시면 됩니다.
    //일단 CSS 및 스타일컴포넌트는 건드리지 않겠습니다.
    <H.admin_MainWrap>
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <p>메인화면</p>
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap>
    //여기 구조 따로 안짰음. 구조짤꺼면 박스넣어야함
  );
}

export default AdminMain;
