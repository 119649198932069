import React, {useState, useEffect, useRef}from 'react';
import { useLocation } from 'react-router-dom';
import AdminHeader from '../Section/AdminHeader';
import * as H from '../../Styles/StyleComponentHan';
import { Editor } from '@tinymce/tinymce-react';
import {
  get_editor_data,
  update_editor_data,
} from "../../Axios";
import {
  editor_init_data,
  editor_init_data_mo,
} from "../../Variable";

export function AdminEditorManagement() {
  const division =  new URLSearchParams(useLocation().search).get('editor_division')
  const editorRef = useRef(null);

  const [htmlString, setHtmlString] = useState("");
  const [initialValue, setInitialValue] = useState("");
  const [htmlStringMobile, setHtmlStringMobile] = useState("");
  const [initialValueMobile, setInitialValueMobile] = useState("");

  const getTitle = (division) => {
    switch(division) {
      case '1': return 'CAB 관리 - 회사소개';
      case '2': return 'CAB 관리 - 경영이념';
      case '3': return '서비스 관리 - 컨설팅';
      case '4': return '서비스 관리 - 브랜딩';
      case '5': return '서비스 관리 - 마케팅';
      case '6': return '서비스 관리 - 콘텐츠 제작';
      case '7': return '서비스 관리 - PPL 광고';
      case '8': return '서비스 관리 - 사이트 제작';
      case '9': return '서비스 관리 - 아카이빙 사업';
      case '10': return '서비스 관리 - PG 사업';
      case '11': return '서비스 관리 - WHY? CAB';
      default: return '메뉴 관리';
    }
  }

  useEffect(() => {
    get_editor_data(division).then(result_data=>{
      setHtmlString(result_data[0].editor_content);
      setInitialValue(result_data[0].editor_content);
      setHtmlStringMobile(result_data[0].editor_content_mobile);
      setInitialValueMobile(result_data[0].editor_content_mobile);
    })
  },[division]);

  return (
    <H.admin_MainWrap>
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>{getTitle(division)}</p>
            <div>
              <H.admin_changeBtn
                onClick={()=>{
                  update_editor_data(
                    division,
                    htmlString.replace( /'/gi, '"'),
                    htmlStringMobile.replace( /'/gi, '"'),
                  ).then(result=>{
                    alert('수정이 완료되었습니다.');
                    window.location.reload();
                  })
                }}
              >수정</H.admin_changeBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>

        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.listInfoText>
              <p className="listInfoTextBold">사용 방법 안내</p>
              <p>1. 보안 관계로 ''(작은따옴표)는 사용할 수 없습니다.</p>
              <p>2. ''(작은따옴표)를 사용한 경우에는 자동으로 ""(큰따옴표)로 변경됩니다.</p>
              <p>3. 이미지 파일명은 반드시 영문명으로 지정해야 오류가 나지 않습니다.</p>
              <p>4. 서식 적용이 되지 않을 경우 "서식 - 서식지우기" 아이콘을 활용하여 서식을 초기화 한 후 작성해주세요.</p>
              <p>5. 이미지 등록 시 PC는 최대 가로길이 1200px로 맞춰야 이미지 잘림 없이 볼 수 있습니다.</p>
            </H.listInfoText>
            <H.admin_editorBoxTit>
              PC용 에디터
              <span>(스크린 최소 사이즈 기준 1200px)</span>
            </H.admin_editorBoxTit>
            <Editor
              apiKey='kzj2jdtduql44vtgurqnrj4otlx3bkvkiznzk18tvm41yrcj'
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={initialValue}
              init={editor_init_data}
              onEditorChange={(newValue, editor) => {
                setHtmlString(newValue);
              }}
            />
            <H.admin_editorBoxTit>
              모바일용 에디터
              <span>(스크린 최소 사이즈 기준 480px)</span>
            </H.admin_editorBoxTit>
            <Editor
              apiKey='kzj2jdtduql44vtgurqnrj4otlx3bkvkiznzk18tvm41yrcj'
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={initialValueMobile}
              init={editor_init_data_mo}
              onEditorChange={(newValue, editor) => {
                setHtmlStringMobile(newValue);
              }}
            />
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

export default AdminEditorManagement;
