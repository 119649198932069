import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import * as H from '../../Styles/StyleComponentHan';
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import {
  get_editor_data,
} from "../../Axios";
import {isMobile} from 'react-device-detect';

function Editor() {
    const division =  new URLSearchParams(useLocation().search).get('editor_division')
  const [editorData, setEditorData] = useState([]);
  const [editorMobileData, setEditorMobileData] = useState([]);

  useEffect(() => {
    get_editor_data(division).then(result_data=>{      
      setEditorData(result_data[0].editor_content);
      setEditorMobileData(result_data[0].editor_content_mobile);
    })
  },[division]);
  return (
    <>
      <Header/>
      <H.editorView_mainWrap>
        <div className="editorView_mainInner">
          <div className="editorView_mobile" dangerouslySetInnerHTML={{__html: isMobile ? editorMobileData : editorData}}/>
          <div className="editorView_pc" dangerouslySetInnerHTML={{__html: isMobile ? editorMobileData : editorData}}/>
        </div>
      </H.editorView_mainWrap>
      <Footer/>
    </>
  );
}

export default Editor;
