import React, {useEffect, useState, useRef} from 'react';
import AdminHeader from '../Section/AdminHeader';
import { ReactTabulator } from 'react-tabulator';
import * as H from '../../Styles/StyleComponentHan';
import {
  get_menu_permission,
} from "../../Axios";

export function MenuPermissionManagement() {  
  const [menuPermissionData, setMenuPermissionData] = useState([]);
  const tabulatorRef = useRef();

  const check_menu_permission_edit = () =>{
    if(tabulatorRef.current.getSelectedData().length > 1){
      alert('수정할 데이터를 하나만 선택해주세요.');
    } else if(tabulatorRef.current.getSelectedData().length === 0){
      alert('수정할 데이터를 선택해주세요.');
    } else{
      window.location.href = `/MenuPermissionManagementAdd?menu_permission_ID=${tabulatorRef.current.getSelectedData()[0].menu_permission_ID}`;
    }
  }

  useEffect(() => {
    get_menu_permission().then(result_data=>{
      setMenuPermissionData(result_data);
    })
  },[]);

  return (
    <H.admin_MainWrap> {/* 전체박스 */}
      <AdminHeader/> {/* 헤더 */}
      <H.admin_rightWrap> {/* 우측 전체박스*/}
        <H.admin_rightTitBox>{/* 우측 박스 */}
          <H.admin_rightTitInner>{/* 우측 이너이너 */}
            <p>시스템 관리 - 메뉴권한</p>
            <div>
              <H.admin_addBtn
                onClick={()=>{window.location.href = "/MenuPermissionManagementAdd";}}>등록
              </H.admin_addBtn>
              <H.admin_changeBtn
                onClick={()=>{check_menu_permission_edit();}}>수정
              </H.admin_changeBtn>
            </div>
            </H.admin_rightTitInner>
          </H.admin_rightTitBox>
          
        <H.admin_rightInfoBox>{/* 우측 아래 */}
          <H.admin_rightInfoInner>
            <H.listInfoText>
              <p className="listInfoTextBold">사용 방법 안내</p>
              <p>1. 삭제는 안되며 수정 버튼을 통해 사용여부를 미사용으로 활용하시면 됩니다.</p>
            </H.listInfoText>
            {
            menuPermissionData.length !== 0 ? 
            <ReactTabulator
              onRef={(r) => (tabulatorRef.current = r.current)}
              data={menuPermissionData}
              columns={[
                {formatter:"rowSelection", titleFormatter:"rowSelection", headerSort:false, 
                  cellClick:function(e, cell){
                    cell.getRow().toggleSelect();
                  }
                },
                { title: "ID", field: "menu_permission_ID", headerSort:false},
                { title: "메뉴권한명", field: "menu_permission_name", headerSort:false},
                { title: "사용 여부", field: "menu_permission_status", headerSort:false},
                { title: "등록 일자", field: "menu_permission_registe", headerSort:false},
                { title: "수정 일자", field: "menu_permission_modified", headerSort:false},
              ]}
              layout={"fitData"}
              />
            : 
            <div>
              <p>데이터가 없습니다.</p>  
            </div>
            }
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

export default MenuPermissionManagement;
