

import React, {useState, useEffect, useCallback} from 'react';
import headerMainLogo from '../../Images/cab_logo.png';
import sideMenuMore from '../../Images/Etc/sideMenu_more.png';
import sideMenuMoreClose from '../../Images/Etc/sideMenu_moreClose.png';
import * as H from '../../Styles/StyleComponentHan';

export function Header() {  

  const [menuStatus, setMenuStatus] = useState(false);
  const [cabMenuStatus, setCabMenuStatus] = useState(false);
  const [serviceMenuStatus, setServiceMenuStatus] = useState(false);

  useEffect(() => {
    if(menuStatus) {
      lockScroll();
    } else {
      openScroll();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuStatus])

  const lockScroll = useCallback(() => {
    document.body.addEventListener('touchmove', touchScroll, {passive : false}); 
    document.body.style.overflowY = 'hidden';
  }, []);
  const openScroll = useCallback(() => {
    document.body.removeEventListener('touchmove', touchScroll, {passive : false});
    document.body.style.overflowY = 'scroll';
  }, []);

  const touchScroll = e => e.preventDefault();

  return (
  
    <H.headerDiv>
      <H.headerInner>
        <H.headerMainLogo>
          <a href='/'>
            <img src={headerMainLogo} alt="콘텐츠아카이브로고" />
          </a>
        </H.headerMainLogo>
        <button 
          onClick={()=>{setMenuStatus(!menuStatus)}}
        >메뉴</button>
        <div 
          className={menuStatus === true ? 'blackScream': ' visibility_hidden'}
        >
        </div>
        <div 
          className={menuStatus === true ? 'sideMenu': 'sideMenuOpen'}
        >
          <div className={menuStatus === true ? 'sideMenuInner': 'display_none'}>
            <div className='sideMenuTop'>
              <a href='/'>
                <img src={headerMainLogo} alt="콘텐츠아카이브로고" />
              </a>
              <button
                onClick={()=>{setMenuStatus(!menuStatus)}}
              >닫기</button>
            </div>

            <H.gnbMenu>
              <div>
                <div className="sideMenuFlex" onClick={()=>{setCabMenuStatus(!cabMenuStatus)}}>
                  <p>CAB</p>
                  <img src={cabMenuStatus ? sideMenuMoreClose:sideMenuMore} alt="열기닫기"></img>
                </div>
                <div className={cabMenuStatus === true ? 'sideMenuSub': 'display_none'}>
                  <a href='/Editor?editor_division=1'>회사소개</a>
                  <a href='/Editor?editor_division=2'>경영이념</a>
                </div>
              </div>
              <div>
                <div className="sideMenuFlex" onClick={()=>{setServiceMenuStatus(!serviceMenuStatus)}}>
                  <p>서비스</p>
                  <img src={serviceMenuStatus ? sideMenuMoreClose:sideMenuMore} alt="열기닫기"></img>
                </div>
                <div className={serviceMenuStatus === true ? 'sideMenuSub': 'display_none'}>
                  <a href='/Editor?editor_division=3'>컨설팅</a>
                  <a href='/Editor?editor_division=4'>브랜딩</a>
                  <a href='/Editor?editor_division=5'>마케팅</a>
                  <a href='/Editor?editor_division=6'>콘텐츠 제작</a>
                  <a href='/Editor?editor_division=7'>PPL 광고</a>
                  <a href='/Editor?editor_division=8'>사이트 제작</a>
                  <a href='/Editor?editor_division=9'>아카이빙 사업</a>
                  <a href='/Editor?editor_division=10'>PG 사업</a>
                </div>
              </div>
              <div>
                <a href='/Editor?editor_division=11'>WHY? CAB</a>
              </div>
              <div>
                <a href='/Portfolio'>포토폴리오</a>
              </div>
              <div className="gnbMenuSns">
                <a href="https://www.facebook.com/KoreaTintercom/" target="blank" title="facebook">
                  <img width="24" height="24" src="https://www.volvocars.com/static/shared/icons/v2/thirdparty-facebook-24.svg#light-primary" role="presentation" alt="" className="_SN-cc _SN-cf _SN-cg _SN-ch"/>
                </a>   
                <a href="https://www.instagram.com/optimum_korea/" target="blank" title="youtube">
                  <img width="24" height="24" src="https://www.volvocars.com/static/shared/icons/v2/thirdparty-instagram-24.svg#light-primary" role="presentation" alt="" className="_SN-cc _SN-cf _SN-cg _SN-ch"/>
                </a> 
                <a href="https://www.youtube.com/user/solargardkorea" target="blank" title="youtube">
                  <img width="24" height="24" src="https://www.volvocars.com/static/shared/icons/v2/thirdparty-youtube-24.svg#light-primary" role="presentation" alt="" className="_SN-cc _SN-cf _SN-cg _SN-ch"/>
                </a>               
              </div>
            </H.gnbMenu>

          </div>
        </div>
      </H.headerInner>
    </H.headerDiv>

  );
}

export default Header;
