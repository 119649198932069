import React, {useEffect, useState, useRef} from 'react';//Header 컴포넌트를 가져온 것이고
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import {
  base_url_export,
  get_rolling_banner_status_1,
  get_small_banner,
  get_fix_banner,
} from "../../Axios";
import { Swiper, SwiperSlide} from 'swiper/react';
import { Navigation, EffectFade, Pagination, Autoplay } from 'swiper';
import * as H from '../../Styles/StyleComponentHan';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
/* import sliderRight from '../../Images/Etc/sliderRight.png';
import sliderLeft from '../../Images/Etc/sliderLeft.png'; */
import quickLinksArrow from '../../Images/Etc/quickLinksArrow.png';
import {isMobile} from 'react-device-detect';

let swiper_loop_count = 1;
function Main() {

  const swiperRef = useRef();

  const [rollingBannerData, setRollingBannerData] = useState([]);
  const [smallBannerData, setSmallBannerData] = useState([]);
  const [fixBannerData, setFixBannerData] = useState([
    {
      fix_banner_link:'',
      fix_banner_image:'',
      fix_banner_image_mobile:'',
      fix_banner_title:'',
    }
  ]);


  useEffect(() => {
    get_rolling_banner_status_1().then(result_data=>{
      setRollingBannerData(result_data);
      setInterval(() => {
        if(swiper_loop_count === result_data.length){
          swiperRef.current.swiper.slideTo(0);
          swiper_loop_count = 1;
        }else{
          swiperRef.current.swiper.slideNext();
          swiper_loop_count++;
        }
      }, 5000);
    })
    get_small_banner().then(result_data=>{
      setSmallBannerData(result_data);
    })
    get_fix_banner('1').then((result) => {
      setFixBannerData(result);
    })
  },[]);

  return (
    <>
      {/* 헤더는 src/Homepage/Section/Header.js에서 수정하면 됨 */}
      <Header/>

      <H.home_Wrap>
        <H.home_mainSliderWrap>
          <Swiper className="home_mainSliderBox"
            ref={swiperRef}
            slidesPerView={1}
            modules={[
              Navigation,
              EffectFade,
              Pagination,
              Autoplay,
            ]}
            spaceBetween={50}
            pagination={{ clickable: true }}
            effect="fade"
          >
            {rollingBannerData !== '' && rollingBannerData.map((element, index) => 
              <SwiperSlide
              key={index}>
                <div>
                  <a href={element.rolling_banner_link} target="_blank" rel="noreferrer">
                    <H.SlideImg
                      rolling_banner_image = {base_url_export + element.rolling_banner_image}
                      rolling_banner_image_mobile = {base_url_export + element.rolling_banner_image_mobile}
                    />
                  </a>
                </div>
              </SwiperSlide>
            )}
          </Swiper>
{/*           <H.home_mainSliderBtns className="slider_left_button" onClick={() => swiperRef.current.swiper.slidePrev()}>
            <img src = {sliderLeft} alt="왼쪽으로 이동" />
          </H.home_mainSliderBtns>
          
          <H.home_mainSliderBtns className="slider_right_button" onClick={() => swiperRef.current.swiper.slideNext()}>
            <img src = {sliderRight} alt="오른쪽으로 이동" />
          </H.home_mainSliderBtns> */}

        </H.home_mainSliderWrap>

        <H.main_sectionBox>
          <div className='main_sectionBoxInner'>
            <H.main_sectionTit>
              콘텐츠아카이브보다
            </H.main_sectionTit>

            <H.main_sectionSliderWrap>
                {smallBannerData !== '' && smallBannerData.map((element, index) => 
                  <H.main_sectionSliderBox
                    key={index}
                  >
                    <a
                      href={element.small_banner_link}
                      key={index}
                    >
                      <p className="main_sectionSliderTit">{element.small_banner_name}</p>
                      <p className="main_sectionSliderImg">
                        <img src={base_url_export + element.small_banner_image} alt="소형배너이미지"/>
                      </p>
                      
                    </a>
                  </H.main_sectionSliderBox>
                )}
                
            </H.main_sectionSliderWrap>
          </div>
        </H.main_sectionBox>

        <H.main_sectionBox>
          <div className='main_sectionBoxInner'>
            <H.main_sectionStory>
              <H.main_sectionTit>
                {fixBannerData[0].fix_banner_title}
              </H.main_sectionTit>
              <a href={fixBannerData[0].fix_banner_link}>
                <img
                  src = {
                    isMobile ? 
                      base_url_export + fixBannerData[0].fix_banner_image_mobile : 
                      base_url_export + fixBannerData[0].fix_banner_image
                    }
                  alt='대형 이미지'
                  />
              </a>
            </H.main_sectionStory>
          </div>
        </H.main_sectionBox>

        <H.main_sectionBox>
          <div className='main_sectionBoxInner'>
            <H.main_sectionTit>
              Quick Links
            </H.main_sectionTit>

            <H.main_sectionQuick>
              <a href='/Editor?editor_division=1'>
                <p>콘텐츠아카이브보다</p>
                <img src ={quickLinksArrow} alt="퀵링크 바로가기"></img>
              </a>
              <a href='/Portfolio'>
                <p>포토폴리오</p>
                <img src ={quickLinksArrow} alt="퀵링크 바로가기"></img>
              </a>
            </H.main_sectionQuick>
          </div>
        </H.main_sectionBox>
      </H.home_Wrap>

      <Footer/>
    </>
  );
}

export default Main;
